<template>
    <div class="h-full flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-brand dark:bg-gray-900 overflow-auto">
        <div>
            <slot name="logo" />
        </div>

        <div class="w-full sm:max-w-md mt-8 px-6 py-4 bg-white dark:bg-gray-800 shadow-md sm:rounded-lg">
            <slot />
        </div>
    </div>
</template>
